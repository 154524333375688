import simpleAcl from '../middleware/simpleAcl'
export default [
  {
    path: "/bills",
    name: "Bills",
    meta: {
      title: 'Yêu cầu xử lý',
      page: "Bills",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/bills/Index.vue")
  }
];
  