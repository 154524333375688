import simpleAcl from '../middleware/simpleAcl'
export default [
  {
    path: "/bill-change-requests",
    name: "BillChangeRequests",
    meta: {
      title: 'Yêu cầu chỉnh sửa',
      page: "BillChangeRequests",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/bill-change-requests/Index.vue")
  }
];
  