import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/sale-targets",
    name: "SaleTargets",
    meta: {
      title: 'Mục tiêu kinh doanh',
      page: "SaleTargets",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "SaleTargets" */ "../../views/sale-targets/Index.vue")
  },
  {
    path: "/sale-targets/create",
    name: "CreateSaleTarget",
    meta: {
      title: 'Tạo mới',
      page: "SaleTargets",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "CreateSaleTarget" */ "../../views/sale-targets/Add.vue")
  },
  {
    path: "/sale-targets/edit/:id",
    name: "EditSaleTarget",
    props: true,
    meta: {
      title: 'Chỉnh sửa',
      page: "SaleTargets",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "EditSaleTarget" */ "../../views/sale-targets/Edit.vue")
  },
  {
    path: "/sale-targets/detail/:id",
    name: "DetailSaleTarget",
    props: true,
    meta: {
      title: 'Mục tiêu',
      page: "SaleTargets",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "EditSaleTarget" */ "../../views/sale-targets/Detail.vue")
  },
];
  