import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/training-locations",
    name: "TrainingLocations",
    meta: {
      title: 'Địa điểm học',
      page: "TrainingLocations",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/training-locations/Index.vue")
  },
  {
    path: "/training-locations/create",
    name: "CreateTrainingLocation",
    meta: {
      title: 'Tạo mới địa điểm học',
      page: "TrainingLocations",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/training-locations/Add.vue")
  },
  {
    path: "/training-locations/edit/:id",
    name: "EditTrainingLocation",
    props: true,
    meta: {
      title: 'Chỉnh sửa địa điểm học',
      page: "TrainingLocations",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/training-locations/Edit.vue")
  },
];
  