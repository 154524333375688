import simpleAcl from '../middleware/simpleAcl'
export default [
  {
    path: "/offline-contracts",
    name: "OfflineContracts",
    meta: {
      title: 'Hợp đồng',
      page: "OfflineContracts",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
        import(/* webpackChunkName: "Contracts" */ "../../views/offline-contracts/Index.vue")
  },
  {
    path: "/offline-contracts/create",
    name: "CreateOfflineContract",
    meta: {
      title: 'Tạo mới hợp đồng',
      page: "OfflineContracts",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "CreateContract" */ "../../views/offline-contracts/Add.vue")
  },
  {
    path: "/offline-contracts/edit/:id",
    name: "EditOfflineContract",
    props: true,
    meta: {
      title: 'Chỉnh sửa hợp đồng',
      page: "OfflineContracts",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "EditContract" */ "../../views/offline-contracts/Edit.vue")
  },
  {
    path: "/offline-contracts/detail/:id",
    name: "DetailOfflineContract",
    props: true,
    meta: {
      title: 'Chi tiết',
      page: "OfflineContracts",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "EditContract" */ "../../views/offline-contracts/Detail.vue")
  },
];
  