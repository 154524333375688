import simpleAcl from '../middleware/simpleAcl'
export default [
  {
    path: "/staff-positions",
    name: "StaffPositions",
    meta: {
      title: 'Cơ Sở',
      middleware: [
        simpleAcl
      ],
      page: "StaffPositions",
    },
    component: () =>
      import("../../views/staff-positions/Index.vue")
  },
  {
    path: "/staff-positions/create",
    name: "CreateStaffPosition",
    meta: {
      title: 'Tạo mới chi nhánh',
      middleware: [
        simpleAcl
      ],
      page: "StaffPositions",
    },
    component: () =>
      import("../../views/staff-positions/Add.vue")
  },
  {
    path: "/staff-positions/edit/:id",
    name: "EditStaffPosition",
    props: true,
    meta: {
      title: 'Chỉnh sửa tài khoản',
      middleware: [
        simpleAcl
      ],
      page: "StaffPositions",
    },
    component: () =>
      import("../../views/staff-positions/Edit.vue")
  },
];
  