import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/products",
    name: "Products",
    meta: {
      title: 'Cơ Sở',
      page: "Products",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "Products" */ "../../views/products/Index.vue")
  },
  {
    path: "/products/create",
    name: "CreateProduct",
    meta: {
      title: 'Tạo mới chi nhánh',
      page: "Products",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "CreateProduct" */ "../../views/products/Add.vue")
  },
  {
    path: "/products/edit/:id",
    name: "EditProduct",
    props: true,
    meta: {
      title: 'Chỉnh sửa tài khoản',
      page: "Products",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "EditProduct" */ "../../views/products/Edit.vue")
  },
];
  