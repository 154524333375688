import simpleAcl from '../middleware/simpleAcl'
export default [
  {
    path: "/online-contracts",
    name: "OnlineContracts",
    meta: {
      title: 'Hợp đồng',
      page: "OnlineContracts",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
        import(/* webpackChunkName: "Contracts" */ "../../views/online-contracts/Index.vue")
  },
  {
    path: "/online-contracts/create",
    name: "CreateOnlineContract",
    meta: {
      title: 'Tạo mới hợp đồng',
      page: "OnlineContracts",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "CreateContract" */ "../../views/online-contracts/Add.vue")
  },
  {
    path: "/online-contracts/edit/:id",
    name: "EditOnlineContract",
    props: true,
    meta: {
      title: 'Chỉnh sửa hợp đồng',
      page: "OnlineContracts",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "EditContract" */ "../../views/online-contracts/Edit.vue")
  },
  {
    path: "/online-contracts/detail/:id",
    name: "DetailOnlineContract",
    props: true,
    meta: {
      title: 'Chi tiết',
      page: "OnlineContracts",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "DetailOnlineContract" */ "../../views/online-contracts/Detail.vue")
  },
];
  