import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/teams",
    name: "Teams",
    meta: {
        title: 'Đội nhóm',
        page: "Teams",
        middleware: [
            simpleAcl
        ]
    },
    component: () =>
        import(/* webpackChunkName: "indexTeam" */ "../../views/teams/Index.vue")
  },
  {
    path: "/teams/create",
    name: "CreateTeam",
    meta: {
        title: 'Tạo mới đội nhóm',
        page: "Teams",
        middleware: [
            simpleAcl
        ]
    },
    component: () =>
        import(/* webpackChunkName: "createTeam" */ "../../views/teams/Add.vue")
  },
  {
    path: "/teams/edit/:id",
    name: "EditTeam",
    props: true,
    meta: {
        title: 'Chỉnh sửa đội nhóm',
        page: "Teams",
        middleware: [
            simpleAcl
        ]
    },
    component: () =>
        import(/* webpackChunkName: "editTeam" */ "../../views/teams/Edit.vue")
  },
      {
          path: "/teams/detail/:id",
          name: "DetailTeam",
          props: true,
          meta: {
            title: 'Chi tiết đội nhóm',
            page: "Teams",
          },
          component: () =>
            import(/* webpackChunkName: "editTeam" */ "../../views/teams/Detail.vue")
      },
  ];
    
  