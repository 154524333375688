import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import { pinia } from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import Datepicker from '@vuepic/vue-datepicker'

import Config from './configs/constant'
import CommonHelpers from './helpers/common'
import VueHighcharts from 'vue3-highcharts';
// import { initFacebookSdk } from './configs/init-facebook-sdk'
import moment from 'moment'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './assets/css/style.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'sweetalert2/dist/sweetalert2.min.css';

// initFacebookSdk();
const app = createApp(App)
app.component('vue-datepicker', Datepicker)
app.provide('$config', Config);
app.provide('$commonHelpers', CommonHelpers);
app.provide('$moment', moment)
app.use(router)
app.use(pinia)
app.use(VueHighcharts)
app.use(VueSweetalert2);
app.mount("#app");
