import simpleAcl from '../middleware/simpleAcl'
export default [
  {
    path: "/users",
    name: "Users",
    meta: {
      title: 'User',
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/users/Index.vue")
  },
  {
    path: "/users/create",
    name: "CreateUser",
    meta: {
      title: 'Tạo mới tài khoản',
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/users/Add.vue")
  },
  {
    path: "/users/edit/:id",
    name: "EditUser",
    props: true,
    meta: {
      title: 'Chỉnh sửa tài khoản',
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/users/Edit.vue")
  },
];
