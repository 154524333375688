import simpleAcl from '../middleware/simpleAcl'
export default [
    {
      path: "/workshop-sections",
      name: "WorkshopSections",
      meta: {
        title: 'workshopSections',
        page: "workshopSections",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/workshop-sections/Index.vue")
    },
    {
      path: "/workshop-sections/create",
      name: "CreateWorkshopSection",
      meta: {
        title: 'Add workshopSection',
        page: "workshopSections",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/workshop-sections/Add.vue")
    },
    {
      path: "/workshop-sections/edit/:id",
      name: "EditWorkshopSection",
      props: true,
      meta: {
        title: 'Edit workshopSection',
        page: "workshopSections",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/workshop-sections/Edit.vue")
    },
  ];
  