import simpleAcl from '../middleware/simpleAcl'
export default [
    {
      path: "/data-source-groups",
      name: "DataSourceGroups",
      meta: {
        title: 'Nhóm nguồn liên hệ',
        page: "DataSourceGroups",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/data-source-groups/Index.vue")
    },
    {
      path: "/data-source-groups/create",
      name: "CreateDataSourceGroup",
      meta: {
        title: 'Tạo mới nhóm nguồn liên hệ',
        page: "DataSourceGroups",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/data-source-groups/Add.vue")
    },
    {
      path: "/data-source-groups/edit/:id",
      name: "EditDataSourceGroup",
      props: true,
      meta: {
        title: 'Chỉnh sửa nhóm nguồn liên hệ',
        page: "DataSourceGroups",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/data-source-groups/Edit.vue")
    },
  ];
  