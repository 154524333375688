import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/event-tags",
    name: "EventTags",
    meta: {
      title: 'Event Tag',
      page: "EventTags",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/event-tags/Index.vue")
  },
  {
    path: "/event-tags/create",
    name: "CreateEventTag",
    meta: {
      title: 'Tạo mới event tag',
      page: "EventTags",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/event-tags/Add.vue")
  },
  {
    path: "/event-tags/edit/:id",
    name: "EditEventTag",
    props: true,
    meta: {
      title: 'Chỉnh sửa event tag',
      page: "EventTags",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/event-tags/Edit.vue")
  },
];
  