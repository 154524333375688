import simpleAcl from "../middleware/simpleAcl";

export default [
  {
    path: "/time-ranges",
    name: "TimeRanges",
    meta: {
      title: "Đội nhóm",
      page: "TimeRanges",
      middleware: [simpleAcl],
    },
    component: () =>
      import(
        /* webpackChunkName: "TimeRanges" */ "../../views/time-ranges/Index.vue"
      ),
  },
  {
    path: "/time-ranges/create",
    name: "CreateTimeRange",
    meta: {
      title: "Tạo mới đội nhóm",
      page: "TimeRanges",
      middleware: [simpleAcl],
    },
    component: () =>
      import(
        /* webpackChunkName: "CreateTimeRange" */ "../../views/time-ranges/Add.vue"
      ),
  },
  {
    path: "/time-ranges/edit/:id",
    name: "EditTimeRange",
    props: true,
    meta: {
      title: "Chỉnh sửa đội nhóm",
      page: "TimeRanges",
      middleware: [simpleAcl],
    },
    component: () =>
      import(
        /* webpackChunkName: "EditTimeRange" */ "../../views/time-ranges/Edit.vue"
      ),
  },
];
