import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/customer-insights",
    name: "CustomerInsights",
    meta: {
      title: 'Thấu hiểu khách hàng',
      page: "CustomerInsights",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "indexCustomerInsight" */ "../../views/customer-insights/Index.vue")
  },
  {
    path: "/customer-insights/create",
    name: "CreateCustomerInsight",
    meta: {
      title: 'Tạo mới',
      page: "CustomerInsights",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "addCustomerInsight" */ "../../views/customer-insights/Add.vue")
  },
  {
    path: "/customer-insights/edit/:id",
    name: "EditCustomerInsight",
    props: true,
    meta: {
      title: 'Chỉnh sửa',
      page: "CustomerInsights",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "editCustomerInsight" */ "../../views/customer-insights/Edit.vue")
  },
];
  