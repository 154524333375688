import simpleAcl from '../middleware/simpleAcl'
export default [
  {
    path: "/contract-change-requests",
    name: "ContractChangeRequests",
    meta: {
      title: 'Yêu cầu hợp đồng',
      page: "ContractChangeRequest",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "ContractChangeRequest" */ "../../views/contract-change-requests/Index.vue")
  },
];
  