import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/teachers",
    name: "Teachers",
    meta: {
      title: 'Cơ Sở',
      page: "Teachers",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "Teachers" */ "../../views/teachers/Index.vue")
  },
  {
    path: "/teachers/create",
    name: "CreateTeacher",
    meta: {
      title: 'Tạo mới chi nhánh',
      page: "Teachers",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "CreateTeacher" */ "../../views/teachers/Add.vue")
  },
  {
    path: "/teachers/edit/:id",
    name: "EditTeacher",
    props: true,
    meta: {
      title: 'Chỉnh sửa tài khoản',
      page: "Teachers",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "EditTeacher" */ "../../views/teachers/Edit.vue")
  },
];
  