import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/speakers",
    name: "Speakers",
    meta: {
      title: 'Diễn giả',
      page: "Speakers",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/speakers/Index.vue")
  },
  {
    path: "/speakers/create",
    name: "CreateSpeaker",
    meta: {
      title: 'Tạo mới diễn giả',
      page: "Speakers",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/speakers/Add.vue")
  },
  {
    path: "/speakers/edit/:id",
    name: "EditSpeaker",
    props: true,
    meta: {
      title: 'Chỉnh sửa diễn giả',
      page: "Speakers",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/speakers/Edit.vue")
  },
];
  