import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/study-levels",
    name: "StudyLevels",
    meta: {
      title: 'Cơ Sở',
      page: "StudyLevels",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "StudyLevels" */ "../../views/study-levels/Index.vue")
  },
  {
    path: "/study-levels/create",
    name: "CreateStudyLevel",
    meta: {
      title: 'Tạo mới chi nhánh',
      page: "StudyLevels",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "CreateStudyLevel" */ "../../views/study-levels/Add.vue")
  },
  {
    path: "/study-levels/edit/:id",
    name: "EditStudyLevel",
    props: true,
    meta: {
      title: 'Chỉnh sửa tài khoản',
      page: "StudyLevels",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "EditStudyLevel" */ "../../views/study-levels/Edit.vue")
  },
  {
    path: "/study-levels/:id/lessons",
    name: "LessonsInStudyLevel",
    props: true,
    meta: {
      title: 'Lộ trình level',
      page: "StudyLevels",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "LessonsInStudyLevel" */ "../../views/study-levels/Lesson.vue")
  },
];
  