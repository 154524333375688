export default {
  SIDEBAR: [
    {
      name: 'Sales',
      text: 'Kinh doanh',
      icon: 'bi bi-shop',
      child: [
        {
          text: 'Liên hệ',
          name: 'Contacts',
        },
        {
          text: 'Hợp đồng Offline',
          name: 'OfflineContracts',
        },
        {
          text: 'Hợp đồng Online',
          name: 'OnlineContracts',
        },
      ]
    },
    {
      name: 'Events',
      text: 'Sự kiện',
      icon: 'bi bi-calendar-event',
      child: [
        {
          text: 'Tag',
          name: 'EventTags',
        },
        {
          text: 'Demo',
          name: 'Demos',
        },
        {
          text: 'Test',
          name: 'TestEvents',
        },
        {
          text: 'Hội thảo',
          name: 'Workshops',
        },
        {
          text: 'Diễn giả',
          name: 'Speakers',
        },
        {
          text: 'Chương trình con',
          name: 'WorkshopSections',
        }
      ]
    },
    {
      name: 'Training',
      text: 'Đào tạo',
      icon: 'bi bi-book',
      child: [
        {
          text: 'Sản phẩm',
          name: 'Products',
        },
        {
          text: 'Level học viên',
          name: 'PlacementLevels',
        },
        {
          text: 'Level lớp học',
          name: 'StudyLevels',
        },
        {
          text: 'Yêu cầu xử lý',
          name: 'ContractChangeRequests',
        }
      ]
    },
    {
      name: 'Marketing',
      text: 'Marketing',
      icon: 'bi bi-badge-hd',
      child: [
        {
          text: 'Form Marketing',
          name: 'MarketingRequestForms',
        },
        {
          text: 'Nhóm nguồn liên hệ',
          name: 'DataSourceGroups',
        },
        {
          text: 'Kênh nguồn liên hệ',
          name: 'DataSources',
        },
        {
          text: 'Insight khách hàng',
          name: 'CustomerInsights',
        },
      ]
    },
    {
      name: 'Setting',
      text: 'Cài đặt',
      icon: 'bi bi-gear',
      child: [
        {
          text: 'Cơ sở kinh doanh',
          name: 'Branches'
        },
        {
          text: 'Bộ phận',
          name: 'Departments',
        },
        {
          text: 'Vai trò - Chức vụ',
          name: 'StaffPositions',
        },
        {
          text: 'Tài khoản ngân hàng',
          name: 'BankAccounts',
        },
        {
          text: 'Thời gian',
          name: 'TimeRanges',
        },
        {
          text: 'Điểm điểm học',
          name: 'TrainingLocations',
        },
        {
          text: 'Phòng học',
          name: 'Classrooms',
        },
        {
          text: 'Trường học',
          name: 'Schools',
        },
      ]
    }, {
      name: 'Accountant',
      text: 'Kế toán',
      icon: 'bi bi-bank',
      child: [
        {
          text: 'Quản lý hóa đơn',
          name: 'Bills',
        }, {
          text: 'Yêu cầu xử lý hóa đơn',
          name: 'BillChangeRequests',
        }
      ]
    }, {
      name: 'Chart',
      text: 'Báo cáo',
      icon: 'bi bi-pie-chart-fill',
      child: [
        {
          text: 'Doanh thu',
          name: 'RevenueBranch',
        },{
          text: 'Hợp đồng',
          name: 'ContractSex',
        }
      ]
    }, {
      text: 'Chỉ tiêu kinh doanh',
      name: 'SaleTargets',
      icon: 'bi bi-record-circle'
    }, {
      name: 'Users',
      text: 'Tài khoản',
      icon: 'bi bi-person-circle',
      child: [
        {
          text: 'Quản lý giáo viên',
          name: 'Teachers',
        },
        {
          text: 'Quản lý tài khoản',
          name: 'Users',
        },
        {
          text: 'Quản lý đội nhóm',
          name: 'Teams',
        },
        {
          text: 'Quản lý kiểu nhóm',
          name: 'TeamTypes',
        },
      ]
    },
  ]
};
