export default {
  perPage: [10, 20, 30, 50],
  defaultPerPage: 20,
  defaultFormatDate: 'dd-MM-yyyy',
  defaultFormatDatetime: 'dd-MM-yyyy HH:mm',
  companies: {
    'binggo': 'BingGo',
    'langgo': 'Langgo',
    'langmaster': 'Langmaster',
  },
  classType: {
    'Online': 'Online',
    'Offline': 'Offline',
  },
  statusDisplay: {
    1: 'Hiển thị',
    0: 'Không hiển thị',
  },
  paymentMethod: {
    'direct': 'Trực tiếp',
    'bank-transfer': 'Chuyển khoản',
    'pos-machine': 'Quẹt thẻ',
  },
  progressReceipt: {
    'initial-deposit': 'Đặt cọc',
    'deposit': 'Nộp thêm',
    'final-settlement': 'Tất toán',
    'surcharge': 'Phụ phí',
  },
  progressPayment: {
    'personal-work': 'Việc cá nhân',
    'change-discount': 'Thay đổi khuyến mãi',
    'change-course': 'Thay đổi khóa học',
    'poor-method': 'Phương pháp không phù hợp',
  },
  progressPaymentRecept: {
    'initial_deposit': 'Đặt cọc',
    'deposit': 'Nộp thêm',
    'final_settlement': 'Tất toán',
    'surcharge': 'Phụ phí',
  },
  posCardType: {
    'visa': 'Visa',
    'mastercard': 'Mastercard',
    'atm': 'Atm',
  },
  teacherType: {
    'admin': 'Quản lý',
    'teacher': 'Giáo viên',
    'teacher-native': 'Giáo viên bản ngữ',
    'coach': 'Trợ giảng',
  },
  weekdays: {
    'monday': 'Thứ 2',
    'tuesday': 'Thứ 3',
    'wednesday': 'Thứ 4',
    'thursday': 'Thứ 5',
    'friday': 'Thứ 6',
    'saturday': 'Thứ 7',
    'sunday': 'Chủ nhật',
  },
};
