export default {
  LOGIN: 'login',
  LOGIN_GOOGLE: 'login-with-provider',
  COMMON: {
    constants: 'config/constants'
  },
  USERS: {
    INDEX: 'users',
    CREATE: 'users/store',
    SHOW: 'users/get-user-by-id',
    UPDATE: 'users/{id}/update',
    DELETE: 'users/delete',
    EXPORT: 'users/export',
    SEARCH: 'users/search',
  },
  TEACHERS: {
    INDEX: 'teachers',
    CREATE: 'teachers/store',
    SHOW: 'teachers/get-teacher-by-id',
    UPDATE: 'teachers/{id}/update',
    DELETE: 'teachers/delete',
    EXPORT: 'teachers/export',
    SEARCH: 'teachers/search',
    GET_ALL: 'teachers/get-all'
  },
  BRANCHES: {
    INDEX: 'branches',
    CREATE: 'branches/store',
    SHOW: 'branches/get-branch-by-id',
    UPDATE: 'branches/{id}/update',
    DELETE: 'branches/delete',
    GET_ALL: 'branches/get-all',
    SEARCH: 'branches/search',
  },
  BANK_ACCOUNTS: {
    INDEX: 'bank-accounts',
    CREATE: 'bank-accounts/store',
    SHOW: 'bank-accounts/get-bank-account-by-id',
    UPDATE: 'bank-accounts/{id}/update',
    DELETE: 'bank-accounts/delete',
    GET_ALL: 'bank-accounts/get-all',
    SEARCH: 'bank-accounts/search',
  },
  BILLS: {
    INDEX: 'bills',
    CREATE_BILL_RECEIPT: 'bills/create-bill-receipt',
    CREATE_BILL_PAYMENT: 'bills/create-bill-payment',
    GET_BY_OFFLINE_CONTRACT: 'bills/get-by-offline-contract',
    GET_BY_ONLINE_CONTRACT: 'bills/get-by-online-contract',
    ACCEPT_BILL: 'bills/accept',
    REJECT_BILL: 'bills/reject',
    SHOW: 'bills/get-bank-account-by-id',
    UPDATE: 'bills/{id}/update',
    DELETE: 'bills/delete',
    EXPORT: 'bills/export',
    GET_ALL: 'bills/get-all',
    SEARCH: 'bills/search',
  },
  DEPARTMENTS: {
    INDEX: 'departments',
    CREATE: 'departments/store',
    SHOW: 'departments/get-department-by-id',
    UPDATE: 'departments/{id}/update',
    DELETE: 'departments/delete',
    GET_ALL: 'departments/get-all',
    SEARCH: 'departments/search',
  },
  STAFF_POSITIONS: {
    INDEX: 'staff-positions',
    SEARCH: 'staff-positions/search',
    CREATE: 'staff-positions/store',
    SHOW: 'staff-positions/get-staff-positions-by-id',
    UPDATE: 'staff-positions/{id}/update',
    DELETE: 'staff-positions/delete',
    GET_API_ROUTES: 'staff-positions/get-api-routes'
  },
  SALE_TARGETS: {
    INDEX: 'sale-targets',
    DETAIL: 'sale-targets/detail',
    CREATE: 'sale-targets/store',
    SHOW: 'sale-targets/get-sale-target-by-id',
    UPDATE: 'sale-targets/{id}/update',
    DELETE: 'sale-targets/delete',
    GET_API_ROUTES: 'sale-targets/get-api-routes',
    CHANGE_DETAIL_SALE_TARGET: 'sale-targets/change-detail-sale-target',
  },
  ACCOUNTANT_FUNDS: {
    SEARCH: 'accountant-funds/search'
  },
  CUSTOMER_INSIGHT: {
    INDEX: 'customer-insights',
    CREATE: 'customer-insights/store',
    SHOW: 'customer-insights/get-customer-insight-by-id',
    UPDATE: 'customer-insights/{id}/update',
    DELETE: 'customer-insights/delete',
  },
  MARKETING_REQUEST_FORM: {
    INDEX: 'marketing-request-forms',
    CREATE: 'marketing-request-forms/store',
    SHOW: 'marketing-request-forms/get-marketing-request-form-by-id',
    UPDATE: 'marketing-request-forms/{id}/update',
    DELETE: 'marketing-request-forms/delete',
  },
  TEAM_TYPES: {
    INDEX: 'team-types',
    SEARCH: 'team-types/search',
    CREATE: 'team-types/store',
    SHOW: 'team-types/get-team-type-by-id',
    UPDATE: 'team-types/{id}/update',
    DELETE: 'team-types/delete',
  },
  TEAMS: {
    INDEX: 'teams',
    SEARCH: 'teams/search',
    CREATE: 'teams/store',
    SHOW: 'teams/get-team-by-id',
    UPDATE: 'teams/{id}/update',
    DELETE: 'teams/delete',
  },
  TEAM_MEMBERS: {
    GET_TEAM_BY_USER: 'team-members/get-team-member-by-user',
    CREATE: 'team-members/create',
    GET_TEAM_MEMBER_BY_TEAM: 'team-members/get-team-members-by-team',
    DELETE: 'team-members/delete',
  },
  CONTACTS: {
    INDEX: 'contacts',
    SEARCH: 'contacts/search',
    CREATE: 'contacts/store',
    SHOW: 'contacts/get-one',
    UPDATE: 'contacts/{id}/update',
    DELETE: 'contacts/delete',
    GET_TYPES: 'contacts/get-types',
    STATISTIC: 'contacts/statistic',
    COUNT_CONTACT_BY_TYPES: 'contacts/count-by-types',
    COUNT_CONTACT_BY_GROUP_OF_TYPES: 'contacts/count-by-group-of-types',
    IMPORT: 'contacts/import',
    EXPORT: 'contacts/export',
  },
  TRIAL_REGISTRATION_MANAGEMENT: {
    INDEX: 'trial-registration-management',
    UPDATE: 'trial-registration-management/{id}/update',
  },
  DATA_SOURCE_GROUPS: {
    INDEX: 'data-source-groups',
    CREATE: 'data-source-groups/store',
    SHOW: 'data-source-groups/get-one',
    UPDATE: 'data-source-groups/{id}/update',
    DELETE: 'data-source-groups/delete',
    GET_ALL: 'data-source-groups/get-all',
    SEARCH: 'data-source-groups/search',
  },
  DATA_SOURCES: {
    INDEX: 'data-sources',
    CREATE: 'data-sources/store',
    SHOW: 'data-sources/get-one',
    UPDATE: 'data-sources/{id}/update',
    DELETE: 'data-sources/delete',
    //GET_ALL: 'sources/get-all',
    SEARCH: 'data-sources/search',
  },
  CHILDREN: {
    INDEX: 'children',
    SEARCH: 'children/search',
    CREATE: 'children/store',
    SHOW: 'children/{id}',
    UPDATE: 'children/{id}/update',
    DELETE: 'children/delete',
  },
  EVENT_TAGS: {
    INDEX: 'event-tags',
    CREATE: 'event-tags/store',
    SHOW: 'event-tags/get-one',
    UPDATE: 'event-tags/{id}/update',
    DELETE: 'event-tags/delete',
    SEARCH: 'event-tags/search',
  },
  TEST_EVENTS: {
    INDEX: 'test-events',
    SEARCH: 'test-events/search',
    CREATE: 'test-events/store',
    SHOW: 'test-events/get-one',
    UPDATE: 'test-events/{id}/update',
    DELETE: 'test-events/delete',
    AVAILABLE_RECORDS: 'test-events/get-available-records'
  },
  TEST_EVENT_PARTICIPANTS: {
    INDEX: 'test-event-participants',
    SEARCH: 'test-event-participants/search',
    CREATE: 'test-event-participants/store',
    SHOW: 'test-event-participants/get-one',
    UPDATE: 'test-event-participants/{id}/update',
    DELETE: 'test-event-participants/delete',
  },
  DEMOS: {
    INDEX: 'demos',
    SEARCH: 'demos/search',
    CREATE: 'demos/store',
    SHOW: 'demos/get-one',
    UPDATE: 'demos/{id}/update',
    DELETE: 'demos/delete',
    AVAILABLE_RECORDS: 'demos/get-available-records'
  },
  DEMO_ATTENDEES: {
    INDEX: 'demo-attendees',
    SEARCH: 'demo-attendees/search',
    CREATE: 'demo-attendees/store',
    SHOW: 'demo-attendees/get-one',
    UPDATE: 'demo-attendees/{id}/update',
    DELETE: 'demo-attendees/delete',
  },
  SPEAKERS: {
    INDEX: 'speakers',
    SEARCH: 'speakers/search',
    CREATE: 'speakers/store',
    SHOW: 'speakers/get-one',
    UPDATE: 'speakers/{id}/update',
    DELETE: 'speakers/delete',
    AVAILABLE_RECORDS: 'speakers/get-available-records'
  },
  WORKSHOPS: {
    INDEX: 'workshops',
    SEARCH: 'workshops/search',
    CREATE: 'workshops/store',
    SHOW: 'workshops/get-one',
    UPDATE: 'workshops/{id}/update',
    DELETE: 'workshops/delete',
    AVAILABLE_RECORDS: 'workshops/get-available-records'
  },
  WORKSHOP_ATTENDEES: {
    INDEX: 'workshop-attendees',
    SEARCH: 'workshop-attendees/search',
    CREATE: 'workshop-attendees/store',
    SHOW: 'workshop-attendees/get-one',
    UPDATE: 'workshop-attendees/{id}/update',
    DELETE: 'workshop-attendees/delete',
    UPDATE_ATTENDANCE_STATUS: 'workshop-attendees/update-attendance-status',
  },
  WORKSHOP_SECTIONS: {
    INDEX: 'workshop-sections',
    CREATE: 'workshop-sections/store',
    SHOW: 'workshop-sections/get-one',
    UPDATE: 'workshop-sections/{id}/update',
    DELETE: 'workshop-sections/delete',
    //GET_ALL: 'sources/get-all',
    SEARCH: 'workshop-sections/search',
  },
  OFFLINE_CONTRACT: {
    INDEX: 'offline-contracts',
    GET_TOTAL_PRICE: 'offline-contracts/get-total-price',
    SEARCH: 'offline-contracts/search',
    CREATE: 'offline-contracts/store',
    SHOW: 'offline-contracts/get-offline-contract-by-id',
    GET_FOR_VIEW: 'offline-contracts/get-offline-contract-for-view',
    UPDATE: 'offline-contracts/{id}/update',
    EXPORT: 'offline-contracts/export',
    ADD_CONTRACT_TO_CLASS: 'offline-contracts/add-contract-to-class',
    DELETE: 'offline-contracts/delete',
  },
  OFFLINE_CLASSES: {
    SEARCH: 'offline-classes/search',
  },
  ONLINE_CLASSES: {
    SEARCH: 'online-classes/search',
  },
  ONLINE_CONTRACT: {
    INDEX: 'online-contracts',
    GET_TOTAL_PRICE: 'online-contracts/get-total-price',
    ADD_CONTRACT_TO_CLASS: 'online-contracts/add-contract-to-class',
    SEARCH: 'online-contracts/search',
    CREATE: 'online-contracts/store',
    SHOW: 'online-contracts/get-online-contract-by-id',
    EXPORT: 'online-contracts/export',
    GET_FOR_VIEW: 'online-contracts/get-online-contract-for-view',
    UPDATE: 'online-contracts/{id}/update',
    DELETE: 'online-contracts/delete',
  },
  TEAM_MEMBER: {
    GET_TEAM_BY_USER: 'team-members/get-team-member-by-user',
    CREATE: 'team-members/create',
    GET_TEAM_MEMBER_BY_TEAM: 'team-members/get-team-members-by-team',
    DELETE: 'team-members/delete',
  },
  PROVINCES: {
    SEARCH: 'provinces/search',
  },
  DISTRICTS: {
    SEARCH: 'districts/search',
  },
  WARDS: {
    SEARCH: 'wards/search',
  },
  PRODUCTS: {
    INDEX: 'products',
    SEARCH: 'products/search',
    CREATE: 'products/store',
    SHOW: 'products/get-product-by-id',
    UPDATE: 'products/{id}/update',
    DELETE: 'products/delete',
  },
  TIME_RANGES: {
    INDEX: 'time-ranges',
    GET_ALL: 'time-ranges/get-all',
    GET_ALL_DATA: 'time-ranges/get-data-all',
    SEARCH: 'time-ranges/search',
    CREATE: 'time-ranges/store',
    SHOW: 'time-ranges/get-time-range-by-id',
    UPDATE: 'time-ranges/{id}/update',
    DELETE: 'time-ranges/delete',
  },
  PLACEMENT_LEVELS: {
    INDEX: 'placement-levels',
    GET_ALL: 'placement-levels/get-all',
    GET_ALL_DATA: 'placement-levels/get-data-all',
    SEARCH: 'placement-levels/search',
    CREATE: 'placement-levels/store',
    SHOW: 'placement-levels/get-placement-level-by-id',
    UPDATE: 'placement-levels/{id}/update',
    DELETE: 'placement-levels/delete',
  },
  STUDY_LEVELS: {
    INDEX: 'study-levels',
    LESSONS: 'study-levels/get-lessons-by-study-level-id',
    SEARCH: 'study-levels/search',
    CREATE: 'study-levels/store',
    SHOW: 'study-levels/get-study-level-by-id',
    UPDATE: 'study-levels/{id}/update',
    UPDATE_LESSON: 'study-levels/update-lesson',
    DELETE: 'study-levels/delete',
  },
  CONTACT_TYPE_CHANGELOGS: {
    INDEX: 'contact-type-changelogs',
  },
  CUSTOMER_SERVICE_LOGS: {
    INDEX: 'customer-service-logs',
    CREATE: 'customer-service-logs/store'
  },
  CONTRACT_CHANGE_REQUESTS: {
    CREATE: 'contract-change-requests/store',
    INDEX: 'contract-change-requests',
    ACCEPT: 'contract-change-requests/accept',
    REJECT: 'contract-change-requests/reject',
  },
  SALES_PERFORMANCE_REVIEWS: {
    INDEX: 'sales-performance-reviews',
    CREATE: 'sales-performance-reviews/store'
  },
  REPORT: {
    REVENUE_BRANCH: 'reports/revenue-branch',
    REVENUE_GROUP: 'reports/revenue-group',
    CONTRACT_SEX: 'reports/contract-sex',
    CONTRACT_AGE: 'reports/contract-age',
    SOURCE_CHANNEL: 'reports/source-channel',
    CONTRACT_LOCATION: 'reports/contract-location',
    CONTRACT_PRODUCT: 'reports/contract-product',
  },
  TRAINING_LOCATIONS: {
    INDEX: 'training-locations',
    CREATE: 'training-locations/store',
    SHOW: 'training-locations/get-one',
    UPDATE: 'training-locations/{id}/update',
    DELETE: 'training-locations/delete',
    GET_ALL: 'training-locations/get-all',
    SEARCH: 'training-locations/search',
  },
  CLASSROOMS: {
    INDEX: 'classrooms',
    CREATE: 'classrooms/store',
    SHOW: 'classrooms/get-one',
    UPDATE: 'classrooms/{id}/update',
    DELETE: 'classrooms/delete',
    GET_ALL: 'classrooms/get-all',
    SEARCH: 'classrooms/search',
  },
  BILL_CHANGE_REQUESTS: {
    INDEX: 'bill-change-requests',
    ADD_DELETE_REQUEST: 'bill-change-requests/add-delete-request',
    ADD_EDIT_BILL_RECEPT: 'bill-change-requests/add-edit-bill-recept-request',
    ADD_EDIT_BILL_PAYMENT: 'bill-change-requests/add-edit-bill-payment-request',
    ACCEPT: 'bill-change-requests/accept',
    REJECT: 'bill-change-requests/reject'

  },
  SCHOOLS: {
    SEARCH: 'schools/search'
  },
  CONTACT_TYPES: {
    SEARCH: 'contact-types/search'
  }
};
