import simpleAcl from '../middleware/simpleAcl'
export default [
    {
      path: "/branches",
      name: "Branches",
      meta: {
        title: 'Cơ Sở',
        page: "Branches",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/branches/Index.vue")
    },
    {
      path: "/branches/create",
      name: "CreateBranch",
      meta: {
        title: 'Tạo mới chi nhánh',
        page: "Branches",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/branches/Add.vue")
    },
    {
      path: "/branches/edit/:id",
      name: "EditBranch",
      props: true,
      meta: {
        title: 'Chỉnh sửa tài khoản',
        page: "Branches",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/branches/Edit.vue")
    },
  ];
  