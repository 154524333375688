export default [
    {
      path: "/test-multi-select/create",
      name: "CreateTest",
      meta: {
        title: 'Tạo mới tài khoản',
        permission: {
          controller: 'UserController',
          action: 'create'
        }
      },
      component: () =>
        import("../../views/test-multi-select/Add.vue")
    },
    {
      path: "/test-multi-select/edit/:id",
      name: "EditTest",
      props: true,
      meta: {
        title: 'Chỉnh sửa tài khoản',
        permission: {
          controller: 'UserController',
          action: 'edit'
        }
      },
      component: () =>
        import("../../views/test-multi-select/Edit.vue")
    },
  ];
  