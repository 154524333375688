import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/demos",
    name: "Demos",
    meta: {
      title: 'Demos',
      page: "Demos",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/demos/Index.vue")
  },
  {
    path: "/demos/create",
    name: "CreateDemo",
    meta: {
      title: 'Add demo',
      page: "Demos",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/demos/Add.vue")
  },
  {
    path: "/demos/edit/:id",
    name: "EditDemo",
    props: true,
    meta: {
      title: 'Edit demo',
      page: "Demos",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/demos/Edit.vue")
  },
  {
    path: "/demos/:id/detail",
    name: "ShowDemo",
    meta: {
      title: 'Chi tiết demo',
      page: "Demos",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/demos/View.vue")
  }
];
  