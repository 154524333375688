import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/departments",
    name: "Departments",
    meta: {
      title: 'Cơ Sở',
      page: "Departments",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/departments/Index.vue")
  },
  {
    path: "/departments/create",
    name: "CreateDepartment",
    meta: {
      title: 'Tạo mới chi nhánh',
      page: "Departments",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/departments/Add.vue")
  },
  {
    path: "/departments/edit/:id",
    name: "EditDepartment",
    props: true,
    meta: {
      title: 'Chỉnh sửa tài khoản',
      page: "Departments",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/departments/Edit.vue")
  },
];
  