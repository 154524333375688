import simpleAcl from '../middleware/simpleAcl'
export default [
    {
      path: "/classrooms",
      name: "Classrooms",
      meta: {
        title: 'Phòng học',
        page: "Classrooms",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/classrooms/Index.vue")
    },
    {
      path: "/classrooms/create",
      name: "CreateClassroom",
      meta: {
        title: 'Tạo mới phòng học',
        page: "Classrooms",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/classrooms/Add.vue")
    },
    {
      path: "/classrooms/edit/:id",
      name: "EditClassroom",
      props: true,
      meta: {
        title: 'Chỉnh sửa phòng học',
        page: "Classrooms",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/classrooms/Edit.vue")
    },
  ];
  