import simpleAcl from '../middleware/simpleAcl'
export default [
    {
      path: "/team-types",
      name: "TeamTypes",
      meta: {
        title: 'Kiểu nhóm',
        page: "TeamTypes",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import(/* webpackChunkName: "TeamTypes" */ "../../views/team-types/Index.vue")
    },
    {
      path: "/team-types/create",
      name: "CreateTeamType",
      meta: {
        title: 'Tạo mới kiểu nhóm liên hệ',
        page: "TeamTypes",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import(/* webpackChunkName: "CreateTeamType" */ "../../views/team-types/Add.vue")
    },
    {
      path: "/team-types/edit/:id",
      name: "EditTeamType",
      props: true,
      meta: {
        title: 'Chỉnh sửa kiểu nhóm liên hệ',
        page: "TeamTypes",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import(/* webpackChunkName: "EditTeamType" */ "../../views/team-types/Edit.vue")
    },
  ];
  