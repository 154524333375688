import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/placement-levels",
    name: "PlacementLevels",
    meta: {
      title: 'Cơ Sở',
      page: "PlacementLevels",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "PlacementLevels" */ "../../views/placement-levels/Index.vue")
  },
  {
    path: "/placement-levels/create",
    name: "CreatePlacementLevel",
    meta: {
      title: 'Tạo mới chi nhánh',
      page: "PlacementLevels",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "CreatePlacementLevel" */ "../../views/placement-levels/Add.vue")
  },
  {
    path: "/placement-levels/edit/:id",
    name: "EditPlacementLevel",
    props: true,
    meta: {
      title: 'Chỉnh sửa tài khoản',
      page: "PlacementLevels",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "EditPlacementLevel" */ "../../views/placement-levels/Edit.vue")
  },
];
  