export default {
    export(data, name) {
        const url = window.URL.createObjectURL(
            new Blob([data], {type: "application/csv"})
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name + `.xlsx`);

        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    },
    checkMinMax(el, min, max) {
        if (el.target.value > max) {
            el.preventDefault();
            return false;
        }

        if (el.target.value < min) {
            el.preventDefault();
            return false;
        }
        return el.target.value
    },
    numberToThousand(el) {
        let number = el.target.value.replace(/[^\d]/g, "");
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    displayToThousand(value) {
        if (value) return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

        return '0';
    },
    convertThousandToNumber(value) {
        if (typeof value === 'string' || value instanceof String) {
            return value.replace(/,/g, "");
        }

        return value;
    },
    generateRouteOfflineContract(product, placementLevelId, placementLevels) {
        if (placementLevelId) {
            let levelChild = placementLevels.find(element => element.id == placementLevelId)
            let listPlacementLevel = placementLevels.filter(function (item) {
                return item.level == levelChild.level
            })
            let index = listPlacementLevel.findIndex(function (item) {
                return item.id == levelChild.id
            })
            let route = '';
            let routeName = '';
            for (let i = 0; i < listPlacementLevel.length; i++) {
                if (i >= index && (i + 1) <= (index + product.sessions/32)) {
                    route += listPlacementLevel[i]['id']
                    route += (i + 1 == index + product.sessions/32) ? '' : ',';
                    routeName += listPlacementLevel[i]['name']
                    routeName += (i +1 == index + product.sessions/32) ? '' : ', ';
                }
            }

            return {
                route: route,
                routeName: routeName,
            }
        }
    },
};
