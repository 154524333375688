<template>
  <div class="dashboard-nav" :class="{ 'mobile-show': (toggleSidebar == 'mobile-show' && isHideSideBar) }">
    <header>
      <a href="#!" class="menu-toggle" style="color: red">
        <i class="fas fa-bars"></i>
      </a>
      <div class="brand-logo">
        <a href="/"><img style="max-width: 170px" src="../../assets/logo.png" /></a>
      </div>
    </header>
    <nav class="dashboard-nav-list">
      <div v-for="(sideBar, index) in sidebarList" :key="index">
        <div v-if="!sideBar.child">
          <a v-show="checkPermission(sideBar)" @click="nextPage(sideBar)" class="dashboard-nav-item"
            :class="{ 'sidebar-active': meta.page == sideBar.name }">
            <i :class="sideBar.icon"></i> <span>{{ sideBar.text }}</span>
          </a>
        </div>
        <div v-else class="dashboard-nav-dropdown" :class="{ 'show': (itemActive == index) }">
          <a @click="setActive(index)" class="dashboard-nav-item dashboard-nav-dropdown-toggle">
            <i :class="sideBar.icon"></i>{{ sideBar.text }}
          </a>
          <div class='dashboard-nav-dropdown-menu'>
            <a v-for="(child, key) in sideBar.child" :key="key" @click="nextPage(child)"
              :class="{ 'd-none': !checkPermission(child), 'sidebar-active': meta.page == child.name }"
              class="dashboard-nav-dropdown-item">
              {{ child.text }}
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script setup>
import { ref, defineProps } from 'vue'
import Sidebar from '../../configs/sidebar'
import { useRouter, useRoute } from 'vue-router'
import { computed } from 'vue'

const router = useRouter()
const route = useRoute();
const meta = computed(() => route.meta)
const sidebarList = ref(Sidebar.SIDEBAR);
const itemActive = ref(null)

const props = defineProps({
  isHideSideBar: Boolean,
  toggleSidebar: String,
  permissionList: Object
});

function setActive(item) {
  if (itemActive.value == item) {
    itemActive.value = null;
  } else {
    itemActive.value = item;
  }
}

function nextPage(item) {
  router.push({ name: item.name })
}

// function dropdownActive(sideBar) {
//     let active = sideBar.child.filter(function(item) {
//         return item.name == meta.value.page;
//     });
//     if (active.length > 0) {
//         return true
//     }
//     return false
// }

function checkPermission(sideBar) {
  if (props.permissionList[sideBar.controller]) {
    return props.permissionList[sideBar.controller].includes(sideBar.action)
  }

  return true;
}

</script>

<script>

export default {
  name: "HeaderComponent",
  data() {
    return {
    }
  }
}
</script>

<style>
:root {
  --font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

nav {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #515151;
  text-align: left;
  background-color: #fff
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: #3f84fc;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #d52327;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Nunito", sans-serif;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
  font-weight: normal;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}

.dashboard {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}

.dashboard-app {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  margin-top: 84px;
}

.dashboard-content {
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  padding: 0 25px 25px 25px;
}

.dashboard-nav {
  min-width: 238px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: #373193;
}

.dashboard-compact .dashboard-nav {
  display: none;
}

.dashboard-nav header {
  min-height: 84px;
  padding: 8px 27px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dashboard-nav header .menu-toggle {
  display: none;
  margin-right: auto;
}

.dashboard-nav a {
  color: #515151;
}

.dashboard-nav a:hover {
  text-decoration: none;
}

.dashboard-nav {
  background-color: #2a3140;
}

.dashboard-nav a {
  color: #fff;
}

.brand-logo {
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #515151;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand-logo:focus,
.brand-logo:active,
.brand-logo:hover {
  color: #dbdbdb;
  text-decoration: none;
}

.brand-logo i {
  color: #d2d1d1;
  font-size: 27px;
  margin-right: 10px;
}

.dashboard-nav-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-nav-item {
  min-height: 56px;
  padding: 8px 20px 8px 70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.02em;
  transition: ease-out 0.5s;
  text-decoration: none;
  font-size: 14px;
}

.dashboard-nav-item:hover {
  /*background-color: #d52327 !important;*/
  background-color: dimgrey;
  color: #fff;
}

.dashboard-nav-item i {
  width: 36px;
  font-size: 19px;
  margin-left: -40px;
}


.active {
  background: rgba(0, 0, 0, 0.1);
}

.dashboard-nav-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-nav-dropdown:has(.sidebar-active) .dashboard-nav-item {
  /*background-color: #AA0000 !important;*/
  color: #fff;
}

.dashboard-nav-dropdown.show {
  background: rgba(255, 255, 255, 0.04);
}

.dashboard-nav-dropdown.show>.dashboard-nav-dropdown-toggle {
  font-weight: bold;
}

.dashboard-nav-dropdown.show>.dashboard-nav-dropdown-toggle:after {
  -webkit-transform: none;
  -o-transform: none;
  transform: none;
}

.dashboard-nav-dropdown.show>.dashboard-nav-dropdown-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-nav-dropdown-toggle:after {
  content: "";
  margin-left: auto;
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(81, 81, 81, 0.8);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dashboard-nav .dashboard-nav-dropdown-toggle:after {
  border-top-color: rgba(255, 255, 255, 0.72);
}

.dashboard-nav-dropdown-menu {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-nav-list div {
  margin-top: 1px;
}

.dashboard-nav-dropdown-item {
  margin-top: 1px;
  min-height: 40px;
  padding: 8px 20px 8px 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transition: ease-out 0.5s;
  text-decoration: none;
  font-size: 14px;
}

.dashboard-nav-dropdown-item:hover {
  /*background-color: #d52327 !important;*/
  background-color: dimgrey;
  color: #fff;
}

.sidebar-active {
  /*background-color: #AA0000 !important;*/
  background: dimgrey;
  color: #fff;
}

.menu-toggle {
  position: relative;
  width: 42px;
  height: 42px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #2a3140 !important;
}

.menu-toggle:hover,
.menu-toggle:active,
.menu-toggle:focus {
  text-decoration: none;
}

.menu-toggle i {
  font-size: 30px;
}

.dashboard-toolbar {
  min-height: 50px;
  background-color: #dfdfdf;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 27px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
}

.nav-item-divider {
  height: 1px;
  margin: 1rem 0;
  overflow: hidden;
  background-color: rgba(236, 238, 239, 0.3);
}

@media (min-width: 992px) {
  .dashboard-app {
    margin-left: 238px;
  }

  .dashboard-compact .dashboard-app {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    padding: 15px 0px;
  }
}

@media (max-width: 992px) {
  .dashboard-nav {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    background-color: #2a3140;
  }

  .dashboard-toolbar-menu {
    background-color: #2a3140 !important;
  }

  .dashboard-toolbar-menu .menu-toggle {
    color: #fff !important;
  }

  .dashboard-nav.mobile-show {
    display: block;
  }
}

.dashboard-toolbar {
  background-color: #EEE;
}

@media (max-width: 992px) {
  .dashboard-nav header .menu-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 992px) {
  .dashboard-toolbar {
    left: 238px;
  }

  .dashboard-compact .dashboard-toolbar {
    left: 0;
  }
}
</style>
