import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/marketing-request-forms",
    name: "MarketingRequestForms",
    meta: {
      title: 'Form khách hàng',
      page: "MarketingRequestForms",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "indexMarketingRequestForms" */ "../../views/marketing-request-forms/Index.vue")
  },
  {
    path: "/marketing-request-forms/create",
    name: "CreateMarketingRequestForm",
    meta: {
      title: 'Tạo mới',
      page: "MarketingRequestForms",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "addMarketingRequestForm" */ "../../views/marketing-request-forms/Add.vue")
  },
  {
    path: "/marketing-request-forms/edit/:id",
    name: "EditMarketingRequestForm",
    props: true,
    meta: {
      title: 'Chỉnh sửa',
      page: "MarketingRequestForms",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import(/* webpackChunkName: "editMarketingRequestForm" */ "../../views/marketing-request-forms/Edit.vue")
  },
];
  