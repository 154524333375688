import simpleAcl from "../middleware/simpleAcl";
export default [
  {
    path: "/reports/revenue-branch",
    name: "RevenueBranch",
    meta: {
      title: "Doanh thu",
      page: "Branches",
      middleware: [simpleAcl],
    },
    component: () => import("../../views/reports/revenues/RevenueBranch.vue"),
  }, {
    path: "/reports/revenue-group",
    name: "RevenueGroup",
    meta: {
      title: "Doanh thu",
      page: "RevenueGroup",
      middleware: [simpleAcl],
    },
    component: () => import("../../views/reports/revenues/RevenueGroup.vue"),
  }, {
    path: "/reports/contract-sex",
    name: "ContractSex",
    meta: {
      title: "Giới tính",
      page: "ContractSex",
      middleware: [simpleAcl],
    },
    component: () => import("../../views/reports/contract/ContractSex.vue"),
  }, {
    path: "/reports/contract-age",
    name: "ContractAge",
    meta: {
      title: "Giới tính",
      page: "ContractAge",
      middleware: [simpleAcl],
    },
    component: () => import("../../views/reports/contract/ContractAge.vue"),
  }, {
    path: "/reports/contract-source-channel",
    name: "SourceChannel",
    meta: {
      title: "Kênh nguồn",
      page: "SourceChannel",
      middleware: [simpleAcl],
    },
    component: () => import("../../views/reports/contract/SourceChannel.vue"),
  }, {
    path: "/reports/contract-location",
    name: "ContractLocation",
    meta: {
      title: "Kênh nguồn",
      page: "SourceChannel",
      middleware: [simpleAcl],
    },
    component: () => import("../../views/reports/contract/ContractLocation.vue"),
  }, {
    path: "/reports/contract-product",
    name: "ContractProduct",
    meta: {
      title: "Kênh nguồn",
      page: "SourceChannel",
      middleware: [simpleAcl],
    },
    component: () => import("../../views/reports/contract/ContractProduct.vue"),
  }
];
