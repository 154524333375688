import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/test-events",
    name: "TestEvents",
    meta: {
      title: 'TestEvents',
      page: "TestEvents",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/test-events/Index.vue")
  },
  {
    path: "/test-events/create",
    name: "CreateTestEvent",
    meta: {
      title: 'Add TestEvent',
      page: "TestEvents",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/test-events/Add.vue")
  },
  {
    path: "/test-events/edit/:id",
    name: "EditTestEvent",
    props: true,
    meta: {
      title: 'Edit TestEvent',
      page: "TestEvents",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/test-events/Edit.vue")
  },
  {
    path: "/test-events/:id/detail",
    name: "ShowTestEvent",
    meta: {
      title: 'Chi tiết',
      page: "TestEvents",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/test-events/View.vue")
  }
];
  