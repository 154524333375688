import {createRouter, createWebHistory} from "vue-router";
import {authStore} from '../store/modules/authStore'
import userRoutes from './modules/user'
import branchRoutes from './modules/branch'
import departmentRoutes from './modules/department'
import staffPositionRoutes from './modules/staffPosition'
import customerInsightRoutes from './modules/customerInsight'
import marketingRequestFormRoutes from './modules/marketingRequestForm'
import testMultiSelectRoutes from './modules/test-multi-select'
import teamRoutes from './modules/team'
import offlineContractRoutes from './modules/offlineContract'
import onlineContractRoutes from './modules/onlineContract'
import productRoutes from './modules/product'
import placementLevelRoutes from './modules/placementLevel'
import billRoutes from './modules/bill'
import bankAccountRoutes from './modules/bankAccount'
import dataSourceGroupRoutes from './modules/dataSourceGroup'
import dataSourceRoutes from './modules/dataSource'
import contactRoutes from './modules/contact'
import demoRoutes from './modules/demo'
import testEventRoutes from './modules/testEvent'
import workshopRoutes from './modules/workshop'
import workshopSectionRoutes from './modules/workshopSection'
import teacherRoutes from './modules/teacher'
import timeRangeRoutes from './modules/timeRange'
import teamTypeRoutes from './modules/teamType'
import saleTargetRoutes from './modules/saleTarget'
import contractChangeRequestRoutes from './modules/contractChangeRequest'
import trainingLocationsRoutes from './modules/trainingLocation'
import classroomRoutes from './modules/classroom'
import studyLevelRoutes from './modules/studyLevel'
import reportRoutes from './modules/report'
import speakerRoutes from './modules/speaker'
import eventTagRoutes from './modules/eventTag'
import billChangeRequestRoutes from './modules/billChangeRequest'
import simpleAcl from './middleware/simpleAcl'
import schoolRoutes from "./modules/school";

const routes = [
  ...userRoutes,
  ...branchRoutes,
  ...departmentRoutes,
  ...staffPositionRoutes,
  ...customerInsightRoutes,
  ...marketingRequestFormRoutes,
  ...teamRoutes,
  ...testMultiSelectRoutes,
  ...offlineContractRoutes,
  ...productRoutes,
  ...placementLevelRoutes,
  ...dataSourceGroupRoutes,
  ...dataSourceRoutes,
  ...contactRoutes,
  ...bankAccountRoutes,
  ...onlineContractRoutes,
  ...billRoutes,
  ...contractChangeRequestRoutes,
  ...timeRangeRoutes,
  ...demoRoutes,
  ...testEventRoutes,
  ...workshopRoutes,
  ...workshopSectionRoutes,
  ...teamTypeRoutes,
  ...saleTargetRoutes,
  ...trainingLocationsRoutes,
  ...classroomRoutes,
  ...teacherRoutes,
  ...studyLevelRoutes,
  ...reportRoutes,
  ...speakerRoutes,
  ...eventTagRoutes,
  ...billChangeRequestRoutes,
  ...schoolRoutes,
  {
    path: "/",
    name: "Home",
    component: () => import("../views/reports/revenues/RevenueBranch.vue"),
    meta: {
      title: 'Home',
      middleware: [
        simpleAcl
      ]
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login.vue")
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Unauthorized.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/commons/PageNotFound.vue")
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

function middlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) {
    return context.next
  }

  return () => {
    const nextPipeline = middlewarePipeline(
      context, middleware, index + 1
    )

    nextMiddleware({...context, next: nextPipeline})
  }
}

router.beforeEach((to, from, next) => {
  const auth = authStore()
  if (!auth.token && to.name != 'Login') {
    next('/login');
  }

  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    auth
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
});

export default router;
