import simpleAcl from '../middleware/simpleAcl'
export default [
    {
      path: "/data-sources",
      name: "DataSources",
      meta: {
        title: 'Kênh nguồn liên hệ',
        page: "DataSources",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/data-sources/Index.vue")
    },
    {
      path: "/data-sources/create",
      name: "CreateDataSource",
      meta: {
        title: 'Tạo mới kênh nguồn liên hệ',
        page: "DataSources",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/data-sources/Add.vue")
    },
    {
      path: "/data-sources/edit/:id",
      name: "EditDataSource",
      props: true,
      meta: {
        title: 'Chỉnh sửa kênh nguồn liên hệ',
        page: "DataSources",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/data-sources/Edit.vue")
    },
  ];
  