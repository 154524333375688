import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/contacts",
    name: "Contacts",
    meta: {
      title: 'Liên hệ',
      page: "Contacts",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/contacts/Index.vue")
  },
  {
    path: "/contacts/create",
    name: "CreateContact",
    meta: {
      title: 'Tạo mới chi nhánh',
      page: "Contacts",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/contacts/Add.vue")
  },
  {
    path: "/contacts/edit/:id",
    name: "EditContact",
    props: true,
    meta: {
      title: 'Chỉnh sửa tài khoản',
      page: "Contacts",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/contacts/Edit.vue")
  },
  {
    path: "/contacts/detail/:id",
    name: "ShowContact",
    props: true,
    meta: {
      title: 'Thông tin liên hệ',
      page: "Contacts",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/contacts/View.vue")
  },
  {
    path: "/contacts/trial-registration-management",
    name: "TrialRegistrationManagement",
    props: true,
    meta: {
      title: 'Quản lý đăng ký học thử',
      page: "Contacts",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/contacts/TrialRegistrationManagement.vue")
  },
  {
    path: "/contacts/import-form",
    name: "ImportContactForm",
    props: true,
    meta: {
      title: 'Import contacts',
      page: "Contacts",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/contacts/ImportContactForm.vue")
  },
  {
    path: "/contacts/chart-of-contact-by-group-of-types",
    name: "ChartOfContactByGroupOfTypes",
    meta: {
      title: 'Báo cáo phân loại liên hệ theo nhóm',
      page: "Contacts",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/contacts/ChartOfContactByGroupOfTypes.vue")
  },
  {
    path: "/contacts/chart-of-contact-by-type",
    name: "ChartOfContactByType",
    meta: {
      title: 'Báo cáo phân loại liên hệ',
      page: "Contacts",
      middleware: [
        simpleAcl
      ]
    },
    component: () => import("../../views/contacts/ChartOfContactByType.vue")
  },
];


  