import simpleAcl from '../middleware/simpleAcl'

export default [
  {
    path: "/workshops",
    name: "Workshops",
    meta: {
      title: 'Hội thảo',
      page: "Workshops",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/workshops/Index.vue")
  },
  {
    path: "/workshops/create",
    name: "CreateWorkshop",
    meta: {
      title: 'Add Workshop',
      page: "Workshops",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/workshops/Add.vue")
  },
  {
    path: "/workshops/edit/:id",
    name: "EditWorkshop",
    props: true,
    meta: {
      title: 'Edit Workshop',
      page: "Workshops",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/workshops/Edit.vue")
  },
  {
    path: "/workshop/:id/detail",
    name: "ShowWorkshop",
    meta: {
      title: 'Chi tiết hội thảo',
      page: "Workshops",
      middleware: [
        simpleAcl
      ]
    },
    component: () =>
      import("../../views/workshops/View.vue")
  }
];
  