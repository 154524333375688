import simpleAcl from '../middleware/simpleAcl'
export default [
    {
      path: "/bank-accounts",
      name: "BankAccounts",
      meta: {
        title: 'Cơ Sở',
        page: "BankAccounts",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/bank-accounts/Index.vue")
    },
    {
      path: "/bank-accounts/create",
      name: "CreateBankAccount",
      meta: {
        title: 'Tạo mới chi nhánh',
        page: "BankAccounts",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/bank-accounts/Add.vue")
    },
    {
      path: "/bank-accounts/edit/:id",
      name: "EditBankAccount",
      props: true,
      meta: {
        title: 'Chỉnh sửa tài khoản',
        page: "BankAccounts",
        middleware: [
          simpleAcl
        ]
      },
      component: () =>
        import("../../views/bank-accounts/Edit.vue")
    },
  ];
  